import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BACKEND_URL;
const apiToken = process.env.REACT_APP_API_TOKEN;

class EditComponent extends React.Component{

 constructor(props){
   super(props);
   this.state = {
     campNumber: "",
     campMessage: "",
     campMax: "",
     campMin: ""
   }
 } 

 render(){
  return (
    <div>
      <div className="form-row justify-content-center">  
        <div className="form-group col-md-12">
          <label htmlFor="input1">Destinatário,Sessão</label>
          <textarea cols="60" rows="5" type="text" className="form-control"  placeholder="5535912346578,sessao1&#10;5535912346578,sessao2&#10;5535912346578,sessao3" value={this.state.campNumber} onChange={(value)=> this.setState({campNumber:value.target.value})}/>
        </div>
        <div className="form-group col-md-12">
          <label htmlFor="input2">Mensagem</label>
          <textarea cols="60" rows="5" type="text" className="form-control"  placeholder="Mensagem" value={this.state.campMessage} onChange={(value)=> this.setState({campMessage:value.target.value})}/>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="input1">Tempo minímo</label>
          <input type="number" className="form-control"  placeholder="Tempo mínimo (segundos)" value={this.state.campMin} onChange={(value)=> this.setState({campMin:value.target.value})}/>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="input1">Tempo máximo</label>
          <input type="number" className="form-control"  placeholder="Tempo máximo (segundos)" value={this.state.campMax} onChange={(value)=> this.setState({campMax:value.target.value})}/>
        </div>
      </div>
      <button type="submit" className="btn btn-primary" onClick={()=>this.sendAction()}>Enviar</button>
    </div>
  );
}

async sendAction(){
  const usersTextArea = this.state.campNumber.split('\n');
  const timer = ms => new Promise(res => setTimeout(res, ms))
  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  for (const user of usersTextArea){
    const rndInt = randomIntFromInterval(this.state.campMin, this.state.campMax)
    await timer(rndInt * 1000)
    const phone = user.split(',')[0];
    const sessao = user.split(',')[1];
    var data = {
      "number": phone,
      "options": {
        "delay": 1200
      },
      "textMessage": {
        "text": this.state.campMessage.replace(/\n/g, "\n")
      }
    }
    var config = {
      method: 'post',
      url: baseUrl + '/message/sendText/' + sessao,
      headers: {
        'apikey': apiToken,
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
      alert('© BOT-ZDG - ' + error)
    });
  }
}
}

export default EditComponent;