import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import ZDG from './module/ZDG';
import ZDGText from './module/ZDGText';
import ZDGFile from './module/ZDGFile';
import ZDGButton from './module/ZDGButton';
import ZDGLocation from './module/ZDGLocation';

function App() {
  return (
    <Router>
    <div className="App">

        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <a className="navbar-brand" href="https://comunidadezdg.com.br" style={{color:'orange',fontWeight:'bold'}}>Comunidade ZDG</a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <Link className="nav-link" to="/"> ZDG Baileys </Link>
              </li>
              <li className="nav-item active">
                <Link className="nav-link" to="/texto"> Disparo de Texto </Link>
              </li>
              <li className="nav-item active">
                <Link className="nav-link" to="/arquivo"> Disparo de Arquivo/Mídia </Link>
              </li>
              <li className="nav-item active">
                <Link className="nav-link" to="/botao"> Disparo de Botão </Link>
              </li>
              <li className="nav-item active">
                <Link className="nav-link" to="/local"> Disparo de Localização </Link>
              </li>
            </ul>
          </div>
        </nav>

        <div className="container py-4">
          <div className="row">
            <Route path="/" exact component={ZDG} />
            <Route path="/texto" exact component={ZDGText} />
            <Route path="/arquivo" exact component={ZDGFile} />
            <Route path="/botao" exact component={ZDGButton} />
            <Route path="/local" exact component={ZDGLocation} />
          </div>
        </div>

      </div>
      </Router>
  );
}

export default App;
