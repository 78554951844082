import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
//import 'sweetalert2/src/sweetalert2.scss'
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BACKEND_URL;
const apiToken = process.env.REACT_APP_API_TOKEN;

class ZDG extends React.Component  {

  constructor(props){
    super(props);
    this.state = {
      campInstancias:[],
      campInstancia: "",
      campResult: ""
    }
  }

  render()
  {
    return (
      <div>
        <h2>ZDG BAILEYS - MULTISESSÃO</h2>
        <hr></hr>
        <button style={{ marginRight: 5 }} className="btn btn-primary" onClick={()=>this.ZDGList()}>Listar Sessões</button>
        <textarea 
          className="form-control"
					name="campPergunta" 
					cols="40" 
					rows="3"
					value={this.state.campInstancias} 
					onChange={(value)=> this.setState({campInstancias:value.target.value})}
					required="required"
					placeholder="Sessão #1&#13;&#10;Sessão #2&#13;&#10;..."
          style={{ marginTop: 15, marginBottom: 30 }}
				></textarea>
        <h3>Controle da Sessão</h3>
        <input style={{ marginBottom: 15 }} type="text" className="form-control" placeholder="Sessão (somente letras e numeros)" value={this.state.campInstancia} onChange={(value)=> this.setState({campInstancia:value.target.value.replace(/\W/g, "")})}/>
        <button style={{ marginRight: 5 }} className="btn btn-success" onClick={()=>this.ZDGInit()}>Iniciar</button>
        <button style={{ marginRight: 5 }} className="btn btn-primary" onClick={()=>this.ZDGQr()}>QrCode na Web</button>
        <button style={{ marginRight: 5 }} className="btn btn-secondary" onClick={()=>this.ZDGInfo()}>Informações</button>
        <button style={{ marginRight: 5 }} className="btn btn-danger" onClick={()=>this.ZDGLogout()}>Logout</button>
        <button className="btn btn-danger" onClick={()=>this.ZDGDel()}>Deletar</button>
        <p><br /></p>
        <p>{this.state.campResult}</p>
        <img src={this.state.img} alt="" />
        <hr></hr>
        <p><a href="https://comunidadezdg.com.br"><img src="./logo192.png" alt="" style={{width: 50, height: 50}}/></a> © Comunidade ZDG</p>
      </div>
    );
  }

  ZDGList(){
    const config = {
      method: 'get',
      url: baseUrl + '/instance/fetchInstances',
      headers: {
        'apikey': apiToken,
        'Content-Type': 'application/json'
      },
    };
    axios(config)
    .then((response) => {
      const dataZDG = JSON.stringify(response.data)
      this.setState({ campInstancias: dataZDG.replace(/]|"/g, "").replace("[", "").replace(",{", "\n\n").replace(/{|"/g, "").replace(/}|"/g, "").replace(/instance:|"/g, "").replace(/,|"/g, "\n") })
    })
    .catch(function (error) {
      console.log(error);
      alert('© BOT-ZDG - Sem tokens ativos.')
    });
  }

  ZDGQr(){
    const newPageUrl = baseUrl + '/instance/qrcode/'+ this.state.campInstancia
    window.open(newPageUrl, "_blank")
  }

  ZDGInit(){
    const config = {
      method: 'post',
      url: baseUrl + '/instance/create',
      headers: {
        'apikey': apiToken,
        'Content-Type': 'application/json'
      },
      data: {'instanceName' : this.state.campInstancia}
    };
    axios(config)
    .then((response) => {
      this.setState({ campResult: 'Instância: ' + JSON.stringify(response.data.instance.instanceName) + 'Status: ' + JSON.stringify(response.data.instance.status)})
    })
    .catch(function (error) {
      console.log(error);
      alert('© BOT-ZDG - Token não inicializado: ' + error)
    });
  }

  ZDGInfo(){
    const config = {
      method: 'get',
      url: baseUrl + '/instance/connectionState/' + this.state.campInstancia,
      headers: {
        'apikey': apiToken,
        'Content-Type': 'application/json'
      },
    };
    axios(config)
    .then((response) => {
      this.setState({ campResult: "Status: " + JSON.stringify(response.data.state)})
    })
    .catch(function (error) {
      console.log(error);
      alert('© BOT-ZDG - Token já removido ou não foi iniciado.')
    });
  }

  ZDGDel(){
    const config = {
      method: 'delete',
      url: baseUrl + '/instance/delete/' + this.state.campInstancia,
      headers: {
        'apikey': apiToken,
        'Content-Type': 'application/json'
      },
    };
    axios(config)
    .then((response) => {
      alert(JSON.stringify(response.data.message))
      //console.log(response)
      this.setState({ campResult: 'Instância: ' + this.state.campInstancia + ' - Status: ' + JSON.stringify(response.data.message)})
    })
    .catch(function (error) {
      console.log(error);
      alert('© BOT-ZDG - Token já removido ou não foi iniciado.')
    });
  }

  ZDGLogout(){
    const config = {
      method: 'delete',
      url: baseUrl + '/instance/logout/' + this.state.campInstancia,
      headers: {
        'apikey': apiToken,
        'Content-Type': 'application/json'
      },
    };
    axios(config)
    .then((response) => {
      alert(JSON.stringify(response.data.message))
      //console.log(response)
      this.setState({ campResult: 'Instância: ' + this.state.campInstancia + ' - Status: ' + JSON.stringify(response.data.message)})
    })
    .catch(function (error) {
      console.log(error);
      alert('© BOT-ZDG - Token já removido ou não foi iniciado.')
    });
  }

}

export default ZDG;